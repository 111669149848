//返回顶部
    jQuery(document).ready(function(){
        // hide #back-top first
        jQuery("#topon").hide();
        // fade in #back-top
        jQuery(function () {
            jQuery(window).scroll(function () {
                // console.log("jQuery(this).scrollTop()="+jQuery(this).scrollTop());
                if (jQuery(this).scrollTop() > 250) {
                    jQuery('#topon').fadeIn();
                } else {
                    jQuery('#topon').fadeOut();
                }
            });

            // scroll body to 0px on click
            jQuery('#topon .to_top').click(function () {
                jQuery('body,html').animate({
                    scrollTop: 0
                }, 800);
                return false;
            });
        });

    });

//找茬
$("#modal-find").on("click","#_content",function(){
    var _content= $('#message_content').val();
    $.post(
        '/sendProposal' ,
        {'content':_content},
        function(_resp) {
            $('#modal-find').modal('hide');
            $('#alert_com').html('感谢你的宝贵建议，野孩子小伙伴会尽快处理');
            $('#modal-alert').modal()
        }
    );
});
//未登录时弹出
function show_login() {
    $('#review-modal').modal('show');
}

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
Date.prototype.Format = function (fmt) { //author: meizz
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
};
// footer 二维码点击事件
$(document).ready(function(){
    $("#weixin-popup").click(function(){
        $(".wechat-popup").toggle();
    });
    $(document).click(function(){
        $(".wechat-popup").hide();
    });
    $("#weixin-popup").click(function(e){
        e.stopPropagation();
    });
});
// -----------用户信息卡片---------------
var _userInfo = {};
var u_id = $('.nav-user-name').attr('data-user-id');

if($(window).width()>768 ) {
    $('[data-toggle="user-popover"]').popover({
        trigger: 'manual',
        placement: 'auto bottom', //top, bottom, left or right
        html: 'true',
        container: 'body',
        content: function () {
            ContentMethod($(this).attr('data-user-id'));
            return $('#popover-content').html();
        }
    }).on("mouseenter", function () {
        var pop = $('.popover');
        for (var i = 0; i < pop.length; i++) {
            if (i < pop.length) {
                $(pop[i]).remove();
            }
        }
        $(this).popover("show");
    }).on("mouseleave", function () {
        var _this = this;
        setTimeout(function () {
            if (!$(".popover:hover").length) {
                $(_this).popover("hide")
            }
        }, 100);
    });
}

function ContentMethod(user_id) {
    $('.spinner').show();
    $('.gwk-profile-card').hide();
    if(_userInfo.info &&  _userInfo.info.user_id == user_id){
        renderPopover(_userInfo,user_id);
    }else{
        $.ajax({
            url: '/ajax/get/user_info/'+user_id,
            dataType: "json",
            cache:true,
            success: function(data){
                _userInfo = data;
                renderPopover(data,user_id);
            }
        });
    }
}
function renderPopover(data,user_id) {
    var user_info = data;
    var _info = $('#popover-content');
    _info.find('.name').html(user_info.info.nickname || user_info.info.username);
    _info.find('.avatar-link').attr('href','space/'+user_id);
    _info.find('.item-togos').attr('href','space/'+user_id);
    _info.find('.item-beens').attr('href','space/'+user_id);
    _info.find('.item-fans').attr('href','space/'+user_id);
    _info.find('.avatar-link>img').attr('src',user_info.info.avatar).attr('alt',user_info.info.nickname || user_info.info.username);
    _info.find('.home_address').html(user_info.info.home_address || '未知');
    _info.find('.tagline').html(user_info.info.aboutme || '暂无简介');
    _info.find('.count-togos').html(user_info.togos || 0);
    _info.find('.count-beens').html(user_info.beens || 0);
    _info.find('.count-fans').html(user_info.fans || 0);
    _info.find('#popover-follow').attr('data-id',user_id);
    _info.find('#popover-cancel').attr('data-id',user_id);
    if(user_info.info.sex == 1){
        _info.find('sex').removeClass('fa-venus');
        _info.find('sex').addClass('fa-mars');
    }else{
        _info.find('sex').removeClass('fa-mars');
        _info.find('sex').addClass('fa-venus');
    }
    if(user_info.is_follow ==1){
        _info.find('#popover-cancel').show();
        _info.find('#popover-follow').hide();
    }else if(user_info.is_follow == 0){
        _info.find('#popover-cancel').hide();
        _info.find('#popover-follow').show();
    }else{
        _info.find('#popover-cancel').hide();
        _info.find('#popover-follow').hide();
    }
    $('.spinner').hide();
    $('.popover .popover-content').html('');
    $('.popover .popover-content').html(_info.html());
    $('.gwk-profile-card').show();
}
function _addFans(btn){
    var _fan_uid= $(btn).attr("data-id");
    if(u_id>0){
        $.get(
            '/user/add_follow/' + $.base64.encode(JSON.stringify({
                fanUid    : _fan_uid
            })),
            function(_resp) {
                if('success' == _resp.status) {
                    _userInfo.is_follow = 1;
                    $(btn).parent().find('#popover-follow').hide();
                    $(btn).parent().find('#popover-cancel').show();
                    swal({
                        title: '已关注',
                        type: 'success',
                        width: '300'
                    });
                } else if('error' == _resp.status) {
                    swal({
                        title: '关注失败',
                        type: 'info',
                        width: '300'
                    });
                }
            }
        );
    }else{
        $('#review-modal').modal('show');
    }
}
function _cancelFans(btn){
    var _fan_uid= $(btn).attr("data-id");
    if(u_id>0){
        $.get(
            '/user/cancel_fans/' + $.base64.encode(JSON.stringify({
                fanUid    : _fan_uid
            })),
            function(_resp) {
                if('success' == _resp.status) {
                    _userInfo.is_follow = 0;
                    $(btn).parent().find('#popover-follow').show();
                    $(btn).parent().find('#popover-cancel').hide();
                    swal({
                        title: '取消关注成功',
                        type: 'success',
                        width: '300'
                    });
                } else if('error' == _resp.status) {
                }
            }
        );
    }else{
        $('#review-modal').modal('show');
    }
}